import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { REPORT } from "./endpoints";

export default class ReportService {
    static async fetchReportList(variables: any): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORTS, {
                params: variables,
            });
            return data;
        } catch (error) {
            return error;
            // return resolveRequestError(error);
        }
    }

    static async fetchReportListOnUserDesk(variables: any): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORTS_ON_USER_DESK, {
                params: variables,
            });
            return data;
        } catch (error) {
            return error;
            // return resolveRequestError(error);
        }
    }

    static async fetchReportById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportHistory(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT_HISTORY(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportDetails(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT_DETAILS(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async makeReviewer(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_REVIEWER, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async makeCreator(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_CREATOR, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async makeSignatory(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_SIGNATORY, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitReport(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reassignReviewer(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.REASSIGN_REVIEWER(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async startReview(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.START_REVIEW(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async cancelReview(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.CANCEL_REVIEW(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reviewSection(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.REVIEW_SECTION(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reviewPrinciple(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.REVIEW_PRINCIPLE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async deleteFlag(id: string, flagId: string): Promise<any> {
        try {
            const data = await http.post(REPORT.DELETE_FLAG(id, flagId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportFlags(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.GET_REPORT_FLAGS(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitForCertification(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT_FOR_CERTIFICATION(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async issueCertification(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.ISSUE_CERTIFICATION(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async sendBackToReviewer(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.SEND_BACK_TO_REVIEWER(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async addFines(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.ADD_FINES(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async deleteFine(id: string, fineId: string): Promise<any> {
        try {
            const data = await http.post(REPORT.DELETE_FINE(id, fineId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async getFines(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.GET_FINES(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
