/* eslint-disable @typescript-eslint/no-explicit-any */

import http from "@/utils/http";
import { COMPANY } from "./endpoints";

export default class CompanyService {
    /**
     * Get Company Positions
     */
    static async fetchPositions(): Promise<any> {
        const data = await http.get(COMPANY.FETCH_POSITIONS);
        return data;
    }

    /**
     * Get Company Profile
     * @constructor
     *  @param id
     */
    static async fetchProfile(id: string): Promise<any> {
        const data = await http.get(COMPANY.FETCH_PROFILE(id));
        return data;
    }

    /**
     * Update Company profile
     * @param payload
     */
    static async updateProfile(payload: unknown): Promise<any> {
        const data = await http.post(COMPANY.UPDATE_PROFILE, payload);
        return data;
    }
}
