export const ACCOUNT = {
    LOGIN: "/Account/login",
    FORGOT: "/Account/ForgotPassword",
    RESET: "/Account/ResetPassword",
    REFRESH_TOKEN: "/Account/Refresh-token",
    FETCH_PROFILE: "/Account/Profile/Update",
    UPDATE_PROFILE: "/Account/Profile/Update",
    FETCH_CLAIMS: "/Regulator/Claims",
    CHANGE_PASSWORD: "/Account/ChangePassword",
    LOGOUT: "/Account/Logout",
    VERIFY_EMAIL: "Account/Verify",
};

export const COMPANY = {
    SIGNUP: "/Company/Signup",
    RESEND_ACTIVATION: "/Company/SendConfirmation",
    FETCH_POSITIONS: "/Company/Positions",
    FETCH_PROFILE: (id: string): string => `/Regulator/${id}/details`,
    UPDATE_PROFILE: "/Regulator/Update",
};

export const USER = {
    FETCH_PROFILE: "",
};

export const USERS = {
    LIST: (role: string): string => `/User/List?role=${role}`,
    DISABLED_USERS: (status: string): string => `/User/List?status=${status}`,
    ACTIVE_USERS: "User/Active/List",
    CREATE: "User/Create",
    FETCH_SINGLE_USER: (userId: string): string => `/User/${userId}/Update`,
    UPDATE_SINGLE_USER: (userId: string): string => `/User/${userId}/Profile/Update`,
    UPDATE_SINGLE_USER_ROLES: (userId: string): string => `/User/${userId}/Roles/Update`,
    CREATE_ROLE: "Role/Create",
    FETCH_CREATORS: "Report/Settings/CreateUsers",
    FETCH_REVIEWERS: "Report/Settings/ReviewUsers",
    // this is the actual endpoint to fetch creators and reviewers
    LEVELS: "Report/Settings/Level",
    SEND_CREDENTIALS: (id: string): string => `/User/${id}/SendCredentialsToUser`,
    DELETE_USER: (userId: string): string => `/User/${userId}/Disable`,
    ENABLE_USER: (userId: string): string => `/User/${userId}/Enable`,
    ALL_ROLES: "/Role/List",
};

export const REPORT = {
    FETCH_REPORTS: "/Report/List",
    FETCH_REPORTS_ON_USER_DESK: "/Report/MyDesk",
    FETCH_REPORT: (id: string): string => `/Report/${id}/details`,
    FETCH_REPORT_HISTORY: (id: string): string => `/Report/${id}/History`,
    FETCH_REPORT_DETAILS: (id: string): string => `/Report/${id}/update`,
    PREVIEW_REPORT: (id: string): string => `/Report/${id}/Preview`,
    SUBMIT: (id: string): string => `/Report/${id}/Submit`,
    REVIEW: (id: string): string => `/Report/${id}/Review`,
    MAKE_REVIEWER: "/Report/Settings/ReviewUsers",
    MAKE_CREATOR: "/Report/Settings/CreateUsers",
    MAKE_SIGNATORY: "/Report/Settings/SignatoryUsers",
    REASSIGN_REVIEWER: (id: string): string => `/Report/${id}/ReassignReviewer`,
    START_REVIEW: (id: string): string => `/Report/${id}/StartReview`,
    CANCEL_REVIEW: (id: string): string => `/Report/${id}/CancelReview`,
    REVIEW_SECTION: (id: string): string => `/Report/${id}/ReviewSection`,
    REVIEW_PRINCIPLE: (id: string): string => `/Report/${id}/ReviewPrinciple`,
    GET_REPORT_FLAGS: (id: string): string => `/Report/${id}/GetFlags`,
    DELETE_FLAG: (id: string, flagId: string): string => `/Report/${id}/DeleteFlag/${flagId}`,
    SUBMIT_FOR_CERTIFICATION: (id: string): string => `/Report/${id}/SubmitForCertification`,
    ISSUE_CERTIFICATION: (id: string): string => `/Report/${id}/IssueCertification`,
    SEND_BACK_TO_REVIEWER: (id: string): string => `/Report/${id}/SendBackToReviewer`,
    ADD_FINES: (id: string): string => `/Report/${id}/AddFine`,
    GET_FINES: (id: string): string => `/Report/${id}/GetFines`,
    DELETE_FINE: (id: string, fineId: string): string => `/Report/${id}/DeleteFine?fineId=${fineId}`,
};

export const FILE = {
    UPLOAD: "/File/Upload",
    GET: (filename: string): string => `/File/Get/${filename}`,
    DELETE: (filename: string): string => `/File/Delete/${filename}`,
};
