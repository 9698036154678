/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { USERS } from "./endpoints";

export default class UserService {
    static async createUser(body: any): Promise<any> {
        try {
            const data = await http.post(USERS.CREATE, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createRole(body: any): Promise<any> {
        try {
            const data = await http.post(USERS.CREATE_ROLE, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * fetch active users
     */
    static async fetchActiveUsers(): Promise<any> {
        try {
            const data = await http.get(USERS.ACTIVE_USERS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchRoles(): Promise<any> {
        try {
            const data = await http.get(USERS.ALL_ROLES);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchUsers(role: string): Promise<any> {
        try {
            const data = await http.get(USERS.LIST(role));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchLevels(): Promise<any> {
        try {
            const data = await http.get(USERS.LEVELS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    } 

    static async fetchDisabledUsers(status: string): Promise<any> {
        try {
            const data = await http.get(USERS.DISABLED_USERS(status));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async sendUserCredentials(id: string): Promise<any> {
        try {
            const data = await http.post(USERS.SEND_CREDENTIALS(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Delete Single User
     * @param userId
     */
    static async deleteSingleUser(userId: string): Promise<any> {
        try {
            const data = await http.post(USERS.DELETE_USER(userId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async enableUser(userId: string): Promise<any> {
        try {
            const data = await http.post(USERS.ENABLE_USER(userId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Fetch Single User
     * @param userId
     */
    static async fetchSingleUser(userId: string): Promise<any> {
        try {
            const data = await http.get(USERS.FETCH_SINGLE_USER(userId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * User Single User
     * @param userId
     * @param body
     */
    static async updateSingleUser({
        userId,
        body,
    }: {
        userId: string;
        body: any;
    }): Promise<any> {
        try {
            const data = await http.post(
                USERS.UPDATE_SINGLE_USER(userId),
                body,
            );
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * User Single User Roles
     * @param userId
     * @param body
     */
    static async updateSingleUserRoles({
        userId,
        body,
    }: {
        userId: string;
        body: Array<string>;
    }): Promise<any> {
        try {
            const data = await http.post(
                USERS.UPDATE_SINGLE_USER_ROLES(userId),
                body,
            );
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
