/* eslint-disable @typescript-eslint/no-explicit-any */

import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { FILE } from "./endpoints";

export default class FilesService {
    /**
     * Upload Files
     * @constructor
     */
    static async uploadFile(payload: unknown): Promise<any> {
        try {
            return await http.post(FILE.UPLOAD, payload, {
                baseURL: "https://bodadminv2.azurewebsites.net",
            });
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Fetch File Details
     * @param payload
     */
    static async fetchFile(payload: string): Promise<any> {
        try {
            return await http.get(FILE.GET(payload), {
                baseURL: "https://bodadminv2.azurewebsites.net",
            });
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Remove File
     * @param payload
     */
    static async removeFile(payload: string): Promise<any> {
        try {
            return await http.delete(FILE.DELETE(payload), {
                baseURL: "https://bodadminv2.azurewebsites.net",
            });
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
